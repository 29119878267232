import * as React from "react";
import MNIST from '../../imgs/MNIST.png'
import MNISTclick from '../../imgs/MNISTclick.png'
import Mont from '../../imgs/Mont.png'
import MontClick from '../../imgs/MontClick.png'
import trailClick from '../../imgs/trailClick.png'
import trail from '../../imgs/trail.png'
import Ptabs from "../../components/tabs/PsubTabs";

function Other() {
    const projects = [
        {
            title: "Montesori",
            description:
                "Assisted in establishing an international exchange program for Yimi Children’s House Montessori school in Hangzhou, China. General tasks included preparatory research and on site collaboration with Yimi Children’s House faculty and interested parents.",
            image: Mont,
            clkimg: MontClick,
            link: "https://digital.wpi.edu/concern/student_works/8c97kq961?locale=en",
            id: 0,
        },
        {
            title: "Trail Recomendation Application",
            description:
                "Created recommendation system using merge sort and Gower distance with a User-friendly interface",
            image: trail,
            clkimg: trailClick,
            link: "https://github.com/fabigazi/TrailRecommender",
            id: 1,
        },
    ];
    return (
        <Ptabs projects={projects} />
    );
}

export default Other;