import * as React from "react";
import MNIST from '../../imgs/MNIST.png'
import MNISTclick from '../../imgs/MNISTclick.png'
import Ptabs from "../../components/tabs/PsubTabs";

function Machine() {
    const projects = [
        {
            title: "MAGMA",
            description:
                "MAGMA Implements a Genetic Adversarial Attack on the MNIST dataset using Torchvision, pandas, and Python, demonstrating expertise in adversarial attacks and neural network misclassification. Built a robust Convolutional network to train using the MNIST training set as well as adversarial examples, using Pytorch and a LeNet5 structure.",
            image: MNIST,
            clkimg: MNISTclick,
            link: "https://github.com/fabigazi/FAI_Adversarial",
            id: 0,
        },
        {
            title: "Various Machine Learning Techniques for Detecting Alzheimer’s Disease in MRIs",
            description:
                "Using these MRIs, we implement three different classifiers to classify the level of dementia. We evaluate their performance and suitability for a clinical/production setting. It is crucial to identify Alzheimer’s as early as possible, as it provides one with a better chance to benefit from treatment.",
            image: "https://www.cidrap.umn.edu/sites/default/files/styles/article_detail/public/article/iStock-1292254104.jpg?itok=EScrAQ6Q",
            clkimg: "https://www.brainline.org/sites/default/files/slides/mri.jpg",
            link: "https://digital.wpi.edu/concern/student_works/rv042w50z?locale=en",
            id: 1,
        },
    ];
    return (
        <Ptabs projects={projects} />
    );
}

export default Machine;