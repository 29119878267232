import React, { useRef, useState } from "react";
import "./skills.css";
import { languages , databases, markup} from "./skillsData"
import Title from "../../components/divide/title";


const ScrollElement = ({ data, leftNewClick, rightNewClick, ref, size}) => {
    return (
        <div className="scroll-container">


            {/* <div className="button-div">
                <Button label={"<"} newClick={leftNewClick} />
            </div> */}

            <div
                ref={ref}
                style={{
                    width: "80%",
                    overflowX: "scroll",
                    scrollBehavior: "smooth",
                }}
            >

                <div className="content-box">
                    {data.map((item) => (
                        <div className="card" key={item.id} style={{ backgroundColor: item.color }}>
                            <div className="skill-item">
                                <img alt="" className="skill-img" src={item.img} />
                            </div>
                            <p>{item.name}</p>
                        </div>
                    ))}
                </div>
            </div>
            {/* <div className="button-div">
                <Button label={">"} newClick={rightNewClick} />
            </div> */}
        </div>


    );
}

function Skills() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollPosition1, setScrollPosition1] = useState(0);
    const [scrollPosition2, setScrollPosition2] = useState(0);

    const containerRef = useRef();
    const containerRef1 = useRef();
    const containerRef2 = useRef();

    // Function to handle scrolling when the button is clicked
    const handleScroll = (scrollAmount, index) => {
        if (index === 0) {
            // Calculate the new scroll position
            const newScrollPosition = scrollPosition + scrollAmount;

            // Update the state with the new scroll position
            setScrollPosition(newScrollPosition);

            // Access the container element and set its scrollLeft property
            containerRef.current.scrollLeft = newScrollPosition;
        } else if (index === 1) {
            const newScrollPosition = scrollPosition1 + scrollAmount;
            setScrollPosition1(newScrollPosition);
            containerRef1.current.scrollLeft = newScrollPosition;
        } else if (index === 2) {
            const newScrollPosition = scrollPosition2 + scrollAmount;
            setScrollPosition2(newScrollPosition);
            containerRef2.current.scrollLeft = newScrollPosition;
        }
    };

    return (
        <div>

            <Title title={"Languages"} id={"languages"} />

            <ScrollElement data={languages} leftNewClick={() => handleScroll(-200, 0)} rightNewClick={() => handleScroll(200, 0)} ref={containerRef} />

            <Title title={"Databases"} id={"databases"} />
            
            <ScrollElement data={databases} leftNewClick={() => handleScroll(-200, 0)} rightNewClick={() => handleScroll(200, 0)} ref={containerRef} />

            <Title title={"Other"} id={"other"} />
            
            <ScrollElement data={markup} leftNewClick={() => handleScroll(-200, 0)} rightNewClick={() => handleScroll(200, 0)} ref={containerRef} />
        </div>
    );
}

export default Skills;