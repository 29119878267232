import * as React from "react";
import './profile.css';
import character from '../../imgs/Portfolio-Character.png';


function Profile() {
  return (
    <section className="hero-content">
      
      <img
        loading="lazy"
        src={character}
        alt="Profile"
        className="profile-image"
      />
      <h1 className="greeting">Hey</h1>
      <h2 className="name">I'm Fabian Gaziano</h2>
      <div className="title-container">
        <h3 className="title">
          Machine Learning Engineer
        </h3>
        <div className="title-separator" />
        <h3 className="title">
          Software Engineer
        </h3>
      </div>
      <p className="bio">
        I completed a Masters degree in Computer Science from Northeastern
        University. I am also working as a Software Engineer/ IT consultant at QHI, inc. I
        have a bachelors degree from Worcester Polytechnic Institute (WPI) in Computer
        Science. I have a passion for math and physics, and I enjoy finding innovative
        solutions to problems using my professional and educational experience.
      </p>
    </section>
  )
}

export default Profile