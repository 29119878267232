import { useState, useEffect } from 'react';
import React from "react";
import './experiencecard.css'

function ExperienceCard({ title, company, date, description, image, isLeft }) {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isSmallScreen) {
        return (
            <div>
                <div className='experience-row-center'>
                    <div className="experience-card">
                        <div className="experience-details">
                            <h3 className="experience-title">{title}</h3>
                            <p className="experience-company">{company}</p>
                            <p className="experience-description">{description}</p>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    return (
        <div>
            {/* need a condition for below 1000px*/}
            {isLeft ? (
                <div className="experience-row-left">
                    <div className="experience-card">
                        <div className="experience-details">
                            <h3 className="experience-title">{title}</h3>
                            <p className="experience-company">{company}</p>
                            <p className="experience-description">{description}</p>
                        </div>
                    </div>
                    <div className="experience-image-container">
                        <div className="experience-image">
                            <img src={image} alt={title} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="experience-row-right">
                    <div className="experience-image-container">
                        <div className="experience-image">
                            <img src={image} alt={title} />
                        </div>
                    </div>
                    <div className="experience-card">
                        <div className="experience-details">
                            <h3 className="experience-title">{title}</h3>
                            <p className="experience-company">{company}</p>
                            <p className="experience-description">{description}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ExperienceCard;