import * as React from "react";
import './divide.css'

const Title = ({ title, id }) => (
    <div className="div-container" id={id}>
        <header>
            <h1 className="title-heading">{title}</h1>
        </header>
    </div>
);

export default Title;